import { Component, OnInit, EventEmitter, Input, ViewChildren  , Output, Renderer2 } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { SettingsService } from '../../services/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { EgretNotifications2Component } from '../egret-notifications2/egret-notifications2.component';
import { environment } from 'environments/environment';
import localeSrCyrlBA from '@angular/common/locales/sr-Cyrl-BA';
import localeSrLatnBA from '@angular/common/locales/sr-Latn-BA';
import { registerLocaleData } from '@angular/common'; 
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Notifications2Service } from '../egret-notifications2/notifications2.service';
import { DataService } from 'app/shared/services/websocket.service';
@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  @ViewChildren(EgretNotifications2Component) noti;
  public availableLangs = this.settings.availableLangs;
  currentLang = this.settings.getLanguage()
  profilePic;
  public egretThemes;
  public layoutConf: any;
  numOfNotifs = 0;
  notifications = []
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private notifService: Notifications2Service,
    public translate: TranslateService,
    private renderer: Renderer2,
    private wsservice: DataService,
    private settings: SettingsService,
    public jwtAuth: JwtAuthService
  ) {
    this.notifService.notifsChange.subscribe(value => {
      this.notifications = value
      this.numOfNotifs = value.filter(function (el) { return el['read'] == false }).length
    });
  }
  ngOnInit() {
    this.wsservice.connect();
    this.wsservice.messagesSubject$.subscribe({
      complete : () => {
      }
    });
    this.wsservice.sendMessage("testing")
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.currentLang = this.settings.getLanguage()
    this.translate.use(this.currentLang['code']);
    if (this.jwtAuth.getUser()) {
      if (this.jwtAuth.getUser().profile_picture) {
        let httpOptionsBLOB = new HttpHeaders({
          'Content-Type': 'text/plain',
          'observe': 'response',
          'Authorization': `Bearer ${this.jwtAuth.getJwtToken()}`
        })
        this.http.post < any > (`${environment.apiURL}/clients/profile_picture`, {
          'profile_picture_path': this.jwtAuth.getUser().profile_picture
        }, {
          headers: httpOptionsBLOB, responseType: 'blob' as 'json'
        }).subscribe({
          next: pp => {
            let objectURL = URL.createObjectURL(pp);
            this.profilePic = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          },
          error: error => {
            
          }
        });
      }
    }
  }
  
  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng['code']);
    this.settings.setLanguage(lng)
  }
  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
  }
  toggleNotific() {
    this.notificPanel.toggle()
    this.notifService.markAllAsRead()
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
        sidebarCompactToggle: false
      }, {transitionClass: true});
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact',
      sidebarCompactToggle: true
    }, {transitionClass: true});

  }

  onSearch(e) {
    //   console.log(e)
  }
}
