import { Injectable, Inject, EventEmitter, Output } from '@angular/core';
import { LocalStoreService } from './local-store.service';

import localeSrCyrlBA from '@angular/common/locales/sr-Cyrl-BA';
import localeSrLatnBA from '@angular/common/locales/sr-Latn-BA';
import { registerLocaleData } from '@angular/common'; 
@Injectable()
export class SettingsService {
  public availableLangs = [{
    name: 'LAT',
    code: 'latinica',
    flag: 'flag-icon-rs',
    isocode: 'sr-Latn-BA'
  }, {
    name: 'ЋИР',
    code: 'cirilica',
    flag: 'flag-icon-rs',
    isocode: 'sr-Cyrl-BA'
  }];

  @Output() langUpdated = new EventEmitter();

  currentLang = localStorage.getItem("language") ?  JSON.parse(JSON.parse(localStorage.getItem("language"))) : this.availableLangs[0];
  
  

  constructor(
    private ls: LocalStoreService,
  ) {
    if(this.currentLang['code'] == 'latinica') {
      registerLocaleData(localeSrLatnBA, 'sr-BA');
    }else {
      registerLocaleData(localeSrCyrlBA, 'sr-BA');
    }
   }

  getLanguage() {
    return this.currentLang
  }
  setLanguage(langtoset) {
    this.currentLang=langtoset;
    this.ls.setItem("language", JSON.stringify(this.currentLang))
    this.langUpdated.emit(this.currentLang);
    if(langtoset['isocode'] == 'sr-Cyrl-BA' ) {
      registerLocaleData(localeSrCyrlBA, 'sr-BA');
    } else {
      registerLocaleData(localeSrLatnBA, 'sr-BA');
    }
  }
}
