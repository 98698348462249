<mat-toolbar class="topbar mat-bg-card">
    <!-- Sidenav toggle button -->
    <button *ngIf="layoutConf.sidebarStyle !== 'compact'" mat-icon-button id="sidenavToggle" (click)="toggleSidenav()" matTooltip="Toggle Hide/Open">
    <mat-icon>menu</mat-icon>
  </button>

    <span fxFlex></span>
    <!-- Language Switcher -->
    <button mat-button [matMenuTriggerFor]="menu">
    <span class="flag-icon {{currentLang.flag}} mr-8"></span>
    <span>{{currentLang.name}}</span>
  </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let lang of availableLangs" (click)="setLang(lang)">
        <span class="flag-icon mr-8 {{lang.flag}}"></span>
        <span>{{lang.name}}</span>
  </button>
    </mat-menu>

    <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

    <!-- Notification toggle button -->
    <button mat-icon-button matTooltip="Notifications" (click)="toggleNotific()" [style.overflow]="'visible'" class="topbar-button-right">
    <mat-icon>notifications</mat-icon>
    <span *ngIf="numOfNotifs > 0 " class="notification-number mat-bg-warn">{{numOfNotifs}}</span>
  </button>
    <!-- Top left user menu -->
    <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right img-button">
      <img *ngIf="!profilePic" src="assets/images/avatars/avatarosnovni.svg">
      <img *ngIf="profilePic" [src]="profilePic" />
  </button>

    <mat-menu #accountMenu="matMenu">
        <button mat-menu-item [routerLink]="['/settings/me']">
      <mat-icon>settings</mat-icon>
      <span>{{ "ACCSETTING" | translate}}</span>
    </button>
        <button mat-menu-item (click)="jwtAuth.signout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{ "SIGNOUTHEADER" | translate }}</span>
    </button>
    </mat-menu>
</mat-toolbar>