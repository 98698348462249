import {
  Injectable
} from '@angular/core';
import {
  webSocket,
  WebSocketSubject
} from 'rxjs/webSocket';
import {
  catchError,
  tap,
  switchAll,
  map,
  take
} from 'rxjs/operators';
import {
  BehaviorSubject,
  EMPTY,
  Subject
} from 'rxjs';
import {
  environment
} from 'environments/environment';
import {
  JwtAuthService
} from './auth/jwt-auth.service';
import {
  Notifications2Service,
  Notification
} from '../components/egret-notifications2/notifications2.service';
import dateFormat from 'date-fns/format'
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  private socket$: WebSocketSubject < any > ;
  private notifs = []
  public messagesSubject$: BehaviorSubject < Object[] > = new BehaviorSubject(this.notifs);

  private WS_ENDPOINT = environment.wsEndpoint
  isMobile = environment.isMobile
  public messages = this.messagesSubject$.pipe(switchAll(), catchError(e => {
    throw e
  }));
  
  constructor(private jwtAuth: JwtAuthService, private nservice: Notifications2Service, private http: HttpClient, private localNotifications: LocalNotifications) {
    
  }
  public connect(reconnect=false ): void {
    var lastDisconnect;
    while (true) {
      console.log("trueing")
      if (this.jwtAuth.getJwtToken() != null) {
        this.WS_ENDPOINT = this.WS_ENDPOINT.concat(this.jwtAuth.getJwtToken())
        console.log("in if  with ", this.jwtAuth.getJwtToken())
        if (!this.socket$ || this.socket$.closed) {
          this.socket$ = this.getNewWebSocket();
          this.nservice.getPrevNotifs()
          const messages = this.socket$.subscribe(
            msg => {
              switch (msg.intent) {
                case 'connected':
                  this.nservice.addNotification(new Notification(msg))
                  if (this.isMobile == true){
                    this.localNotifications.schedule({
                      text: msg.message,
                      led: 'FF0000',
                      icon: 'res://ic_reminder',
                      smallIcon: 'res://ic_reminder',
                      badge: 1,
                      priority: 2,
                      sound: null
                   });
                  }
                  break;
                case 'event':
                  var datestr = new Date(msg.event.start) 
                  this.nservice.addNotification(new Notification({'message': msg.event.title + " zakazano je u " + datestr.getHours() + ":" + datestr.getMinutes(), 'icon': 'notifications', time: msg.time}))
                  if (this.isMobile == true){
                    this.localNotifications.schedule({
                      text: msg.event.title + " zakazano je u " + datestr.getHours() + ":" + String(datestr.getMinutes()).padStart(2, "0"),
                      led: 'FF0000',
                      icon: 'res://ic_reminder',
                      smallIcon: 'res://ic_reminder',
                      badge: 1,
                      priority: 2,
                      sound: null
                   });
                  }
                  var msgtosend = msg.event.title + " zakazano je u " + datestr.getHours() + ":" + datestr.getMinutes()
                  this.nservice.addNotification(new Notification({'message': msgtosend, 'icon': 'notifications', time: msg.time}))
                  break;
              }
            }, // Called whenever there is a message from the server.
            err => console.log(err), // Called if at any pint WebSocket API signals some kind of error.
            () => {
              lastDisconnect = new Date()
            } // Called when connection is closed (for whatever reason).
          );
        }
        break
      }
    }
  }

  private getNewWebSocket() {
    let ws = new WebSocketSubject(this.WS_ENDPOINT)
    return ws
  }
  sendMessage(msg: any) {
    this.socket$.next(msg);
  }
  close() {
    this.socket$.complete();
  }
}