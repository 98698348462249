import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { JwtAuthService } from '../auth/jwt-auth.service';

@Component({
  selector: 'app-confirm-password',
  template: `<h1 matDialogTitle class="mb-8" style="text-align: center">{{ data.title | translate}}</h1>
    <div mat-dialog-content class="mb-16" style="text-align: center">{{ data.message | translate }} 
    <form [formGroup]="confirmation">
    <input type="password" matInput formControlName="password" placeholder="{{'ENTERPASSWORD' | translate}}">
    </form>
    </div>
    <div mat-dialog-actions>
    <button type="button" [disabled]="!confirmation.valid || counter>=3" mat-raised-button color="primary" (click)="checkPass()">{{"CONFIRM" | translate}}</button>
    &nbsp;
    <span fxFlex></span>
    <button type="button" color="accent" mat-raised-button (click)="dialogRef.close(false)">{{"CANCEL" | translate}}</button>
    <br>
    <span class="text-red" style="text-align: center; width:100%" *ngIf="errorMsg">{{errorMsg | translate}}</span>
    </div>`,
})
export class AppComfirmPasswordComponent {
  confirmation= new FormGroup({
    password: new FormControl('', Validators.required),
  });
  errorMsg = ""
  counter= 0
  timer;
  @Output() password = this.confirmation;
  constructor(public jwtAuth: JwtAuthService, private http: HttpClient,
    public dialogRef: MatDialogRef<AppComfirmPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {}
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.jwtAuth.getJwtToken()}`
    })
  };

  checkPass() {
    this.http.post < any > (`${environment.apiURL}/users/check_password`, this.confirmation.value, this.httpOptions).subscribe({
      next:data => {
          this.dialogRef.close(true)
      },
      error: error => {
        this.counter++
        if (this.counter>=3){
          this.errorMsg = "TOOMANYATTEMPTS"
          let seconds = 15;
          this.timer = setInterval(() => this.errorMsg=(`${seconds--}`), 1000);
          setTimeout(() => {
            clearInterval(this.timer);
            this.counter=0;
            this.errorMsg="TIMEELAPSED"
          }, 15999);
        }else {
          this.errorMsg = "INCORRECTPASS"
        }
      }
    } )
  }
}