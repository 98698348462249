import { Component, OnInit } from '@angular/core';
import { Notifications2Service } from './notifications2.service';

@Component({
  selector: 'egret-notifications2',
  templateUrl: './egret-notifications2.component.html',
  styleUrls: ['./egret-notifications2.component.scss']
})
export class EgretNotifications2Component implements OnInit {

  constructor(
    private notificationsService: Notifications2Service
  ) { }

  ngOnInit(): void {
  }

}
