import {
  Observable
} from 'rxjs';
import {
  MatDialogRef,
  MatDialog,
  MatDialogConfig
} from '@angular/material/dialog';
import {
  Injectable
} from '@angular/core';

import {
  AppComfirmPasswordComponent
} from './app-confirm-password.component';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  environment
} from 'environments/environment';
import {
  JwtAuthService
} from '../auth/jwt-auth.service';
import {
  map
} from 'rxjs/operators';

interface confirmData {
  title ? : string,
    message ? : string
  password ? : string
}

@Injectable()
export class AppConfirmPasswordService {

  constructor(private dialog: MatDialog, public jwtAuth: JwtAuthService, private http: HttpClient, ) {}
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.jwtAuth.getJwtToken()}`
    })
  };
  public confirm(data: confirmData = {}): Observable < any > {
    data.title = data.title || 'Confirm';
    data.message = data.message || 'Are you sure?';
    let dialogRef: MatDialogRef < AppComfirmPasswordComponent > ;
    dialogRef = this.dialog.open(AppComfirmPasswordComponent, {
      width: '380px',
      disableClose: true,
      data: {
        title: data.title,
        message: data.message
      }
    });

    return dialogRef.afterClosed()
  }
}