<div class="sidebar-panel">
    <div id="scroll-area" style="background: red;" class="navigation-hold" fxLayout="column">
        <div class="sidebar-hold">

            <!-- App Logo -->
            <div class="branding">
                <img src="assets/images/applogowhite.svg" alt="" class="app-logo">
                <span class="app-logo-text" style="color: #b4d9f3"></span>

                <span style="margin: auto" *ngIf="layoutConf.sidebarStyle !== 'compact'"></span>
                <div class="sidebar-compact-switch" [ngClass]="{active: layoutConf.sidebarCompactToggle}" (click)="toggleCollapse()" *ngIf="layoutConf.sidebarStyle !== 'compact'"><span></span></div>
            </div>

            <!-- Sidebar user -->
            <div class="app-user">
                <div class="app-user-photo">
                    <img *ngIf="!profilePic" src="assets/images/avatars/avatarosnovni.svg" class="mat-elevation-z1" alt="">
                    <img *ngIf="profilePic" [src]="profilePic" />
                </div>
                <span class="app-user-name mb-8">
                    <span *ngIf="jwtAuth?.getUser()">
                        <b>@ {{jwtAuth?.getUser().username}}</b>
                    </span>
                </span>
                <!-- Small buttons -->
                <div class="app-user-controls">
                    <button class="text-muted" mat-icon-button mat-xs-button [matMenuTriggerFor]="appUserMenu">
                    <mat-icon style="color: black;">settings</mat-icon>
                </button>
                    <button class="text-muted" mat-icon-button mat-xs-button matTooltip="Sign Out" routerLink="/sessions/signin">
                    <mat-icon style="color: black;">exit_to_app</mat-icon>
                </button>
                    <mat-menu #appUserMenu="matMenu">
                        <button mat-menu-item routerLink="/settings/me">
                        <mat-icon>settings</mat-icon>
                        <span>{{ "ACCSETTING" | translate}}</span>
                    </button>
                        <button mat-menu-item routerLink="/kalendar">
                        <mat-icon>date_range</mat-icon>
                        <span>{{"CALENDAR" | translate}}</span>
                    </button>
                        <button mat-menu-item (click)="jwtAuth.signout()">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>{{"SIGNOUTHEADER" | translate}}</span>
                    </button>
                    </mat-menu>
                </div>
            </div>
            <!-- Navigation -->
            <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>
        </div>
    </div>
</div>