import {
  Injectable
} from '@angular/core';
import {
  Subject
} from 'rxjs';
import {
  catchError
} from 'rxjs/internal/operators/catchError';
import {
  switchAll
} from 'rxjs/internal/operators/switchAll';
import dateFormat from 'date-fns/format'
import {
  MatSnackBar
} from '@angular/material/snack-bar';
import {
  JwtAuthService
} from 'app/shared/services/auth/jwt-auth.service';
import {
  environment
} from 'environments/environment';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

export class Notification {
  id ? : String;
  message: String;
  icon: String;
  time: Date;
  route: String;
  color: String;
  read: boolean
  constructor(data ? ) {
    data = data || {};
    this.message = data.message
    this.icon = data.icon || 'notifications'
    this.time = new Date(data.time) || new Date()
    this.route = data.route || '/kalendar'
    this.color = data.color || 'primary'
    this.read = data.read || false
  }
  transformDate(date) {
    let returntime = `${date.getHours()}:${date.getMinutes() <10 ?'0':''}${date.getMinutes()}`
    if (date.getDate() != new Date().getDate()) {
      returntime = `${date.getDate()}.${date.getMonth()+1}. ` + returntime
    }
    return returntime
  }
}
@Injectable({
  providedIn: 'root'
})
export class Notifications2Service {
  isPanelOpen: boolean = false;
  notifications = []
  private numOfUnreadSubj$ = new Subject();
  public numOfUnread$ = this.numOfUnreadSubj$.pipe(switchAll(), catchError(e => {
    throw e
  }));

  notifsChange: Subject < Object[] > = new Subject < Object[] > ();
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.jwtAuth.getJwtToken()}`
    })
  };
  constructor(private _snackBar: MatSnackBar, private jwtAuth: JwtAuthService,private http: HttpClient, ) {

  }
  transformDate(date) {
    let returntime = `${date.getHours()}:${date.getMinutes() <10 ?'0':''}${date.getMinutes()}`
    if (date.getDate() != new Date().getDate()) {
      returntime = `${date.getDate()}.${date.getMonth()+1}. ` + returntime
    }
    return returntime
  }
  markAsRead(notif) {
    let l = this.notifications
    l.map(entry => {
      if (entry.message == notif.message) {
        entry.read = true
      }
    })
    this.notifsChange.next(l);
  }

  addNotification(notif) {
    let l = this.notifications
    l.unshift(notif)
    this.notifsChange.next(l)
    this.pushNotificationToScreen(notif)
  }
  addPreviousNotifs(notifs) {
    let l = notifs.concat(this.notifications)
    this.notifications = l
    this.notifsChange.next(l)
  }
  pushNotificationToScreen(notif) {
    let sbr = this._snackBar.open(notif.message, 'X', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 5 * 1000
    });
    sbr.afterDismissed().subscribe(info => {
      if (info.dismissedByAction === true) {
        this.markAsRead(notif)
      }
    });
  }

  markAllAsRead() {
    this.http.get < any > (`${environment.apiURL}/notifications/mark_all_read`, this.httpOptions).subscribe({
      next: data => {
        let l = this.notifications
        l.map(notif => {
          notif.read = true
        })
        this.notifsChange.next(l);
        this.notifications.map(notif => {
          notif.read = true
        })
      }
    })

  }
  getPrevNotifs() {
    this.http.get < any > (`${environment.apiURL}/notifications/last_month`, this.httpOptions).subscribe(
      (res: any) => {
        res.map(no => {
          if (no.intent == "event" && no.event) {
            var datestr = new Date(no.event.start)
            no.message = no.event.title + " zakazano je u " + this.transformDate(datestr)
          }
          let l = this.notifications
          l.push(new Notification(no))
          this.notifsChange.next(l)
        })
      }
    )
  }

  getNotifications() {
    return this.notifications
  }
  clearAll(e) {
    e.preventDefault();
    this.markAllAsRead()
  }
}