import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, Output, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { JwtAuthService } from '../auth/jwt-auth.service';
@Component({
  selector: 'app-success',
  template: `<h1 matDialogTitle class="mb-8" style="text-align: center">{{ data.title | translate}}</h1>
    <div mat-dialog-content class="mb-16" style="text-align: center;  margin: 0">{{ data.message | translate }}</div>
    <div mat-dialog-actions style="margin-bottom: 0;">
    <button type="button" mat-raised-button color="primary" (click)="dialogRef.close()">{{"CLOSEBUTTON" | translate}}</button>`,
})
export class AppSuccessComponent {
  constructor(
    public dialogRef: MatDialogRef<AppSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {}

}