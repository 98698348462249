import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'predmeti/lista',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Prijava'}
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
        data: { title: 'Others', breadcrumb: 'OTHERS'}
      },     
      {
        path: 'predmeti',
        loadChildren: () => import('./views/predmeti/predmeti.module').then(m => m.PredmetiModule),
      },   
      {
        path: 'kalendar',
        loadChildren: () => import('./views/kalendar/kalendar.module').then(m => m.KalendarModule),
        data: {title: 'Kalendar'}
      },   
      {
        path: 'klijenti',
        loadChildren: () => import('./views/klijenti/klijenti.module').then(m => m.KlijentiModule),
      },    
      {
        path: 'dokumenti',
        loadChildren: () => import('./views/dokumenti/dokumenti.module').then(m => m.DokumentiModule),
      },    
      {
        path: 'administracija',
        loadChildren: () => import('./views/administracija/administracija.module').then(m => m.AdministracijaModule),
        data : {title: "Administracija"}
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
      },    
      {
        path: 'editor',
        loadChildren: () => import('./views/editor/editor.module').then(m => m.EditorModule),
        data: {title: 'Editor'}
      }, 
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

