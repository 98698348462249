import { Component, OnInit, Input } from '@angular/core';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html'
})
export class SidenavComponent {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;

  constructor(
    public jwtAuth: JwtAuthService
  ) {}

  ngOnInit() {}

  shouldSeeAdmin(page):Boolean {
    if (this.jwtAuth.getUser()) {
      if (page=='administracija') {
        if(this.jwtAuth.getUser().role_id == 1) {
          return true
        } else {
          return false
        }
      }
      else if (page=='editor') {
        if(this.jwtAuth.getUser().lawyer) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return false
    }
  }
  shouldSeeEditor(page):Boolean {
    if (this.jwtAuth.getUser()) {
      return !(!this.jwtAuth.getUser().lawyer  && page=='editor')
    } else {
      return false
    }
  }

  // Only for demo purpose
  addMenuItem() {
    this.menuItems.push({
      name: 'ITEM',
      type: 'dropDown',
      tooltip: 'Item',
      icon: 'done',
      state: 'material',
      sub: [
        {name: 'SUBITEM', state: 'cards'},
        {name: 'SUBITEM', state: 'buttons'}
      ]
    });
  }
}