import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;  // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  iconMenu: IMenuItem[] = [
    {
      type: 'separator',
      name: ''
    },
    {
      name: 'CASES',
      type: 'link',
      tooltip: 'Slučajevi',
      icon: 'dns',
      state: 'predmeti/lista',
    },
    {
      name: 'CALENDAR',
      type: 'link',
      tooltip: 'Kalendar',
      icon: 'today',
      state: 'kalendar'
    },
    {
      name: 'CLIENTS',
      type: 'dropDown',
      tooltip: 'Klijenti',
      icon: 'person',
      state: 'klijenti',
      sub: [
        { name: 'ADDCLIENT', state: 'novi-klijent'},
        { name: 'INDIVIDUAL', state: 'fizicka-lica' },
        { name: 'LEGALENTITY', state: 'pravna-lica' }
      ]
    },{
      name: 'DOCUMENTS',
      type: 'link',
      tooltip: 'Dokumenti',
      icon: 'library_books',
      state: 'dokumenti/lista',
    },
    {
      name: 'ADMIN',
      type: 'link', 
      tooltip: 'Administracija',
      icon: 'settings',
      state: 'administracija'
    }
  ];


  
  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  constructor() {}

  
  // You can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    
    this.menuItems.next(this.iconMenu);

  }
}
