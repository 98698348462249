import {
  Component,
  OnInit,
  ViewChild,
  Input
} from '@angular/core';
import {
  MatSidenav
} from '@angular/material/sidenav';
import {
  Router,
  NavigationEnd
} from '@angular/router';
import {
  Notifications2Service
} from '../egret-notifications2/notifications2.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {
  @Input() notificPanel;

  // Dummy notifications
  notifications = []
  constructor(private router: Router, private service: Notifications2Service) {
    this.service.notifsChange.subscribe(value => {
      this.notifications = value;
    });
  }

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.notificPanel.close();
      }
    });
  }
  clearAll(e) {
    e.preventDefault();
    this.service.markAllAsRead()
  }
  setNotifs() {
    if (this.notifications) {
      this.notifications = this.notifications.map(notif => {
        notif['time'] = this.timeDifference(notif['time'])
      })
    }
  }
  transformDate(date) {
    let returntime = `${date.getHours()}:${date.getMinutes() <10 ?'0':''}${date.getMinutes()}`
    if (date.getDate() != new Date().getDate()) {
      returntime = `${date.getDate()}.${date.getMonth()+1}. ` + returntime
    }
    return returntime
  }
  timeDifference(previous) {
    let current = Date.now()
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + ' sek.';
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ' min.';
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ' sati';
    } else if (elapsed < msPerMonth) {
      return 'approximately ' + Math.round(elapsed / msPerDay) + ' dana';
    } else if (elapsed < msPerYear) {
      return 'approximately ' + Math.round(elapsed / msPerMonth) + ' mes.';
    } else {
      return 'approximately ' + Math.round(elapsed / msPerYear) + ' god.';
    }

  }
}